<template>
  <el-container>
    <el-aside class="sideBar">
      <el-col
        element-loading-background="rgba(0, 0, 0, 0.3)"
        v-loading.fullscreen="loading"
      >
        <h2>Movie DB</h2>
        <el-menu
          default-active="1"
          @select="menuSelect"
          background-color="#3a3a3a"
          text-color="#fff"
          style="border: none"
          active-text-color="#ffd04b"
        >
          <el-menu-item index="1">
            <i class="el-icon-menu"></i>
            <span slot="title">全部</span>
          </el-menu-item>
          <el-menu-item index="2">
            <i class="el-icon-document"></i>
            <span slot="title">电影</span>
          </el-menu-item>
          <el-menu-item index="3">
            <i class="el-icon-document"></i>
            <span slot="title">剧集</span>
          </el-menu-item>
        </el-menu>
      </el-col>

      <el-col>
        <br />
        <el-tag
          class="movieTag"
          v-for="tag in hotTags"
          :key="tag"
          @click="addTag(tag)"
          >{{ tag }}
        </el-tag>
      </el-col>
      <el-col>
        <el-tag
          class="filterTag"
          :key="tag"
          v-for="tag in filterTags"
          closable
          :disable-transitions="false"
          @close="closeTag(tag)"
        >
          {{ tag }}
        </el-tag>
        <el-input
          class="inputNewTag"
          v-if="tagInputVisible"
          v-model="tagInputValue"
          ref="saveTagInput"
          size="small"
          @keyup.enter.native="handleTagInputConfirm"
          @blur="handleTagInputConfirm"
        >
        </el-input>
        <el-button
          v-else
          class="button-new-tag"
          size="small"
          @click="showTagInput"
          >+ 输入标签</el-button
        >
      </el-col>
    </el-aside>
    <el-container>
      <el-header>
        <el-col class="optionModule" :span="2" :xs="3">
          <el-popover
            ref="popover"
            placement="right"
            width="200"
            trigger="click"
          >
            <div>
              <div id="topTags" class="hotTag">
                <el-tag
                  class="movieTag"
                  v-for="tag in hotTags"
                  :key="tag"
                  @click="addTag(tag)"
                  >{{ tag }}
                </el-tag>
              </div>
              <el-tag
                class="filterTag inlineTag"
                :key="tag"
                v-for="tag in filterTags"
                closable
                :disable-transitions="false"
                @close="closeTag(tag)"
              >
                {{ tag }}
              </el-tag>
              <el-input
                class="inputNewTag"
                v-if="tagInputVisible"
                v-model="tagInputValue"
                ref="saveTagInput"
                size="small"
                @keyup.enter.native="handleTagInputConfirm"
                @blur="handleTagInputConfirm"
              >
              </el-input>
              <el-button
                v-else
                class="button-new-tag"
                size="small"
                @click="showTagInput"
                >+ 输入标签</el-button
              >
            </div>
            <el-button
              v-bind:style="optionButtonStyle"
              class="optionButton"
              icon="el-icon-menu"
              circle
              slot="reference"
            ></el-button>
          </el-popover>
        </el-col>
        <el-col :span="4" :xs="10" :sm="6" :md="6" class="searchBar">
          <el-input
            placeholder="搜索电影标题"
            v-model="q"
            clearable
            @keyup.enter.native="search"
            @clear="search"
          ></el-input>
        </el-col>
        <el-col
          :span="5"
          :offset="10"
          :xs="{ span: 10, offset: 0 }"
          :sm="{ span: 8, offset: 6 }"
          :md="{ span: 7, offset: 9 }"
          class="orderMenu"
        >
          <div>
            <el-button
              :class="{ activeText: order_by === 'year' }"
              type="text"
              @click="sort('year')"
              >按年份</el-button
            >
            <el-button
              :class="{ activeText: order_by != 'year' }"
              type="text"
              @click="sort('rating')"
              >按评分</el-button
            >
          </div>
        </el-col>
      </el-header>
      <el-main>
        <transition name="el-fade-in-linear">
          <el-row class="movieList" :gutter="20" v-show="movieShow">
            <el-col
              :span="8"
              :md="6"
              :lg="4"
              :xl="3"
              v-for="movie in movies"
              :key="movie.id"
              class="movieCard"
            >
              <div class="movieItem">
                <el-popover
                  class="pcPoster"
                  :open-delay="400"
                  placement="right"
                  width="400"
                  trigger="hover"
                >
                  <div class="infomer">
                    <div class="intro">
                      {{ movie.intro }}
                    </div>
                    <div>
                      <el-tag
                        class="movieTag"
                        v-for="tag in movie.tags"
                        :key="tag"
                        @click="addTag(tag)"
                        >{{ tag }}</el-tag
                      >
                    </div>
                  </div>
                  <div
                    slot="reference"
                    class="poster"
                    @click="goToMovie(movie.id)"
                    :style="
                      'background-image: url(' +
                      movie.poster.replace(/\.webp/, '.jpg') +
                      ')'
                    "
                  >
                    <img
                      style="width: 1px; height: 1px"
                      :src="movie.poster.replace(/\.webp/, '.jpg')"
                    />
                  </div>
                </el-popover>
                <div
                  slot="reference"
                  class="poster mobilePoster"
                  @click="goToMovie(movie.id)"
                  :style="
                    'background-image: url(' +
                    movie.poster.replace(/\.webp/, '.jpg') +
                    ')'
                  "
                ></div>
              </div>

              <div class="movieInfo">
                <p class="movieTitle" :title="movie.title">
                  {{
                    movie.title +
                    (movie.original_title !== "" ? "&nbsp;/&nbsp;" : "") +
                    movie.original_title
                  }}
                  <span class="movieYear">&nbsp;( {{ movie.year }})</span>
                </p>
                <div class="doubanRating" v-if="movie.rating !== -1">
                  <el-rate
                    :value="movie.rating / 2"
                    disabled
                    text-color="#ff9900"
                    disabled-void-color=""
                  >
                  </el-rate>
                  <i class="ratingText">
                    {{ movie.rating }}
                  </i>
                </div>
                <div class="doubanRating" v-else>
                  <i class="ratingText1">暂无评分</i>
                </div>
              </div>
            </el-col>
          </el-row>
        </transition>
      </el-main>
    </el-container>
    <div class="tooltiptext" v-show="webAppTip">
      <img
        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAD4AAAA+CAYAAABzwahEAAACFklEQVRoge1bS04CQRDtbscFHoBIYkg8hCYkLjyGuNWNHsCtF9AbGJZ+7qCJilHCOQyGEwibaq2ZZj5M20QkPeDrRwikaOj3pmredNcEeX5xqQUgVNUEqkIQjoYgHA1BOBoi3xPut1oiiiIhpRRKyfh1NB6Lx9c3rzy8C9dai73dnULsqdfzTcN/qZMuLxTVd9Z9w7twrakUk9K/1VQgvJxxiZBxIkupKwDhsBmHFW53dQhzs7k6QMZxSz24egaIjMMKJ4u5gbg6aMa1xdwkgrkBb0tRSx11P74spe6158YlzY8SiWhNbNRqpvmokkakTBqRSVNSZe8ncSXjy2Ahbr4/GfM5Hon3wYeVi1P4UftArJuOaPyDSk4RUIZAPq4MgXxcOZel282mODs9+eVhnI3O7d2PnzmFsxFt1usLJ+QLLtN0nuO283GVMLdw22JjleAyzRmlXr70/AV86vCTzSoPIorvpvCWlefkMWTG8sEnE0vjZlw6huM0eZ/FXRl3Cr/vvgh67iZkSBcnMrECyZjAFMmYKKWnzVajIY4P24V5BsOhuLq+mfd4zgWncCa0aMCu3JZlAROWrL4QtqV5EhilDmpusMKtpa4gzC3cQkoBITy4eg4QGbcJhzA3CuaWAVY4hLnZXB024xLB3Gz7cYxSt+3H//stJAaX+gM3Macal77hXTij2+9XMW0BsH/NCMLRACv8Cz2iOdlhMGeNAAAAAElFTkSuQmCC"
        class="img-rounded"
        style="
          display: inline-block;
          width: 38px;
          border-radius: 5px;
          float: left;
        "
      />
      <span style="margin-left: 10px; display: inline-block; width: 250px"
        >将此web app安装到本机，点击
        <img
          width="18"
          height="18"
          style="display: inline-block"
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACMAAAAmCAMAAAB5ytLYAAAAqFBMVEUAAAD///+A//+qqv+ZzP+SttuAv9+Av+qGyfKMzPKLxeiJxOuJyO2Mxe+GxuyMxuyIw+mKxeqHw+uJxO2JwuyKxe2Kw+2Jxe2Kxu2Jw+yIxOyJxO2JxO2JxeyIw+yJw+uLxeuJxeyJxOuIxeyKxOyKxOyJxO2Jxe2JxOuJxeyKxOuIxOuJxO2Iw+uJxOyJxOyJxOyJxOyJxOyJxe2JxeyJxOyJxOuJxOwDAgImAAAANnRSTlMAAQIDBQcIDBMUFhocHygoLzBARVBTVWFianh9goiJjY2Vm52hpairt8DC3d3j8PP09vf5/f5I5QpwAAAAAWJLR0QB/wIt3gAAAJBJREFUOMtjYEAD3EKcDASAgLa5riB+JRw6ema6BqL4lLBoGsqZ82uayjLhVqNkKiZpzsWqrMGMW42IFANQDQMDK34XgdUw0EyNhJaCPAQIw9RA+fIqqlA16ub6UCAFUwMTMDaBqpE2Z8Zll6zhqJqBU8MnzkZQDSoYVTNE1ciY8/LgAIpGjBA1auZ4ADsDAwDuKCjgkeBotQAAAABJRU5ErkJggg=="
        />
        添加到主屏幕</span
      >
    </div>
  </el-container>
</template>


<script>
// import func from "../../vue-temp/vue-editor-bridge";
const axios = require("axios");
export default {
  name: "home",
  data() {
    return {
      api: process.env.VUE_APP_API,
      token: "",
      loading: false,
      movies: [],
      type: "",
      page: 1,
      limit: 40,
      q: "",
      order_by: "year",
      movieShow: false,
      noMore: false,
      filterTags: [],
      tagInputVisible: false,
      tagInputValue: "",
      hotTags: [
        "美国",
        "中国大陆",
        "香港",
        "日本",
        "韩国",
        "剧情",
        "经典",
        "人性",
        "犯罪",
        "悬疑",
        "科幻",
        "喜剧",
        "2022",
        "2021",
        "2000",
        "1995",
        "贾樟柯",
        "杨德昌",
        "克里斯托弗·诺兰",
        "爱德华·诺顿"
      ],
    };
  },
  computed: {
    //显示PWA提示
    webAppTip: function () {
      if (this.isIos() && !this.isInStandaloneMode()) {
        return true;
      }
      return false;
    },

    optionButtonStyle: function () {
      if (this.filterTags.length > 0) {
        return {
          color: "tomato",
        };
      } else {
        return {
          color: "#606266",
        };
      }
    },
  },
  methods: {
    // 初始化页面
    init: async function () {
      this.getMovies();
    },
    // 监听滚动条
    listenScoller: function () {
      let vm = this;
      window.addEventListener("scroll", function () {
        if (vm.$route.path != "/home") {
          return;
        }
        let scrollTop =
          document.documentElement.scrollTop || document.body.scrollTop;
        let windowHeight =
          document.documentElement.clientHeight || document.body.clientHeight;
        let scrollHeight =
          document.documentElement.scrollHeight || document.body.scrollHeight;
        if (scrollTop + windowHeight + 100 >= scrollHeight) {
          vm.infLoad();
        }
      });
    },
    // 重置请求参数
    reset: function () {
      this.movieShow = false;
      this.movies = [];
      this.type = "";
      this.page = 1;
      this.limit = 40;
      this.q = "";
      this.order_by = "year";
    },
    // 标签变化刷新页面
    refreshByTag: function () {
      this.noMore = false;
      this.page = 1;
      this.movies = [];
      this.getMovies();
    },
    // 获取电影数据
    getMovies: function () {
      let vm = this;
      vm.loading = true;
      let request_body = {
        collection: "movie",
        database: "Douban",
        dataSource: "Cluster0",
        filter: {},
        sort: {},
        skip: (vm.page - 1) * vm.limit,
        limit: vm.limit,
      };
      if (vm.type === "电影") {
        request_body["filter"]["is_tv"] = false;
      }
      if (vm.type === "剧集") {
        request_body["filter"]["is_tv"] = true;
      }
      if (vm.q !== "") {
        request_body["filter"]["title"] = {};
        request_body["filter"]["title"]["$regex"] = vm.q;
        request_body["filter"]["title"]["$options"] = "i";
      }
      request_body["sort"][vm.order_by] = -1;
      request_body["sort"]["_id"] = -1;
      if (vm.filterTags.length > 0) {
        request_body["filter"]["tags"] = {};
        request_body["filter"]["tags"]["$all"] = vm.filterTags;
      }

      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Request-Headers": "*",
        "api-key":
          "LLppSL7L7bjMm7uHavkXOICu9iymDvwn51rADdUM7hXDjEhxVGZ8zPRqnKOdnLu8",
      };

      setTimeout(function () {
        axios
          .post("https://dataapi.8610000.xyz", JSON.stringify(request_body), {
            headers: headers,
          })
          .then(function (response) {
            if (response.data.documents.length == 0) {
              vm.noMore = true;
            }
            response.data.documents.forEach((movie) => {
              vm.movies.push(movie);
              vm.movieShow = true;
            });
            setTimeout(function () {
              vm.loading = false;
            }, 400);
          })
          .catch(function (error) {
            console.log(error);
            vm.loading = false;
            vm.init(true);
          });
      }, 1000);
    },
    // 菜单选择重新获取数据
    menuSelect: function (index) {
      this.reset();
      if (index === "3") {
        this.type = "剧集";
      } else if (index === "2") {
        this.type = "电影";
      } else {
        this.type = "";
      }
      this.getMovies();
    },
    // 滚动条变化加载新数据
    infLoad: function () {
      if (this.noMore || this.loading) {
        return;
      }
      this.page++;
      this.getMovies();
    },
    // 电影排序
    sort: function (key) {
      this.reset();
      this.order_by = key;
      this.getMovies();
    },
    //电影搜索
    search: function () {
      this.page = 1;
      this.movies = [];
      this.getMovies();
    },
    // 跳转电影对应页面
    goToMovie: function (id) {
      this.$router.push({ path: `/movie/${id}` });
    },
    //新增筛选标签
    addTag: function (tag) {
      if (this.filterTags.indexOf(tag) === -1) {
        this.filterTags.push(tag);
        this.refreshByTag();
      }
    },
    // 去除筛选标签
    closeTag: function (tag) {
      let index = this.filterTags.indexOf(tag);
      this.filterTags.splice(index, 1);
      this.refreshByTag();
    },
    // 显示标签输入框
    showTagInput: function () {
      this.tagInputVisible = true;
      this.$nextTick((_) => {
        console.log(_);
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    // 输入标签确认
    handleTagInputConfirm: function () {
      let inputValue = this.tagInputValue;
      if (inputValue !== "" && this.filterTags.indexOf(inputValue) === -1) {
        this.filterTags.push(inputValue);
        this.refreshByTag();
      }
      this.tagInputVisible = false;
      this.tagInputValue = "";
    },
    // UA检测
    isIos: function () {
      let userAgent = window.navigator.userAgent.toLowerCase();
      return /iphone|ipad|ipod/.test(userAgent);
    },
    // Standalone检测
    isInStandaloneMode: function () {
      return "standalone" in window.navigator && window.navigator.standalone;
    },
  },
  mounted() {
    this.init();
    this.listenScoller();
  },
  watch: {
    //监听路由
    $route() {
      if (
        this.$route.path === "/home" &&
        localStorage.newTag &&
        localStorage.newTag != ""
      ) {
        //新标签筛选
        if (this.filterTags.indexOf(localStorage.newTag) === -1) {
          this.filterTags.push(localStorage.newTag);
          this.refreshByTag();
          localStorage.newTag = "";
        }
      }
    },
    filterTags: function () {
      this.noMore = false;
    },
    q: function () {
      this.noMore = false;
    },
  },
};
</script>

<style scoped>
/* 禁止选择 */
.movieList {
  moz-user-select: -moz-none;
  -moz-user-select: none;
  -o-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.tooltiptext {
  position: fixed;
  width: 300px;
  background-color: #f2f8ff;
  color: black;
  font-size: 15px;
  border-radius: 10px;
  padding: 10px;
  z-index: 1;
  bottom: 15px;
  left: calc(50% - 160px);
}

.tooltiptext::after {
  content: " ";
  position: absolute;
  top: 100%;
  margin-left: -150px;
  border-width: 10px;
  border-style: solid;
  border-color: #f2f8ff transparent transparent transparent;
}

/* 加载动画 */
.el-loading-spinner {
  top: 80%;
}
/* 侧栏 */
.el-aside {
  width: 250px !important;
  position: fixed;
}
.sideBar {
  height: 100vh;
  background: #3a3a3a !important;
  text-align: center;
}
.sideBar h2 {
  color: #adb5bd;
}
.sideBar .el-menu {
}

/* 头部 */
.el-header {
  padding-top: constant(safe-area-inset-top); /* 兼容 iOS < 11.2 */
  padding-top: env(safe-area-inset-top); /* 兼容 iOS >= 11.2 */
  position: fixed;
  width: 100%;
  z-index: 2;
  margin-left: 250px;
  backdrop-filter: blur(1px);
}
.optionModule {
  display: none;
}
.optionButton {
  margin: 8px 0 0 0;
  background: hsla(0, 0%, 100%, 0);
  border: none;
  font-size: 25px;
}
.inlineTag {
  display: inline-block !important;
}
.searchBar {
  margin: 10px 0 10px 0;
}
.searchBar >>> input {
  border-radius: 100px;
}

.orderMenu {
  float: right;
  margin-top: 8px;
}
.orderMenu .el-button--text {
  font-size: 19px;
  color: rgba(221, 221, 221, 0.5);
}
.orderMenu .el-button--text :hover {
  color: rgba(221, 221, 221, 0.9);
}

.orderMenu .activeText {
  cursor: pointer;
  color: #ddd;
}

/* 内容 */
.el-main {
  padding-top: constant(safe-area-inset-top); /* 兼容 iOS < 11.2 */
  padding-top: env(safe-area-inset-top); /* 兼容 iOS >= 11.2 */
  background: #3a3a3a;
  margin-left: 250px;
}
.movieList {
  margin-top: 50px;
  padding: 20px;
}

.movieCard {
  margin-bottom: 20px;
}

.movieItem {
  position: relative;
  color: #333;
  display: inline-block;
  width: 100%;
  vertical-align: top;
}
.poster {
  display: block;
  width: 100%;
  height: 0;
  border-radius: 4px;
  position: relative;
  padding-top: 140.44%;
  background-position: center;
  background-size: cover;
}

.intro {
  margin: 10px;
}
.movieTag {
  font-size: 13px;
  padding-bottom: 8px;
  background: #505050;
  margin: 0 8px 8px 0;
  border-radius: 12px;
  color: #dedede;
  word-break: keep-all;
  white-space: nowrap;
  height: 30px;
  border: none;
}
.movieTag:hover {
  cursor: pointer;
}
.movieItem:hover {
  transform: scale(1.1);
  transition-duration: 0.8s;
  transition-delay: 0s;
  cursor: pointer;
}
.button-new-tag {
  background: #464646;
  color: #ececec;
  font-size: 16px !important;
  border: none;
}

.filterTag {
  display: block;
  background: #fff0;
  border: none;
  color: #d3c57c;
  font-size: 18px;
}
.inputNewTag {
  width: 100px;
}
.movieInfo {
  display: block;
  width: 100%;
  margin-left: 10px;
  text-align: left;
}
.movieTitle {
  font-size: 14px;
  font-weight: 700;
  line-height: 23px;
  color: rgba(255, 255, 255, 0.9);
  margin: 4px 0 4px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.doubanRating {
  display: inline-block;
  height: 20px;
  line-height: 20px;
  font-size: 14px;
}
@media screen and (max-width: 600px) {
  .ratingText {
    display: none;
  }
}
.doubanRating >>> i {
  margin-right: 0px;
}
.doubanRating .el-rate {
  display: inline-block;
}
.ratingText, .ratingText1 {
  /* display:inline-block; */
  color: #ff9900;
}

.mobilePoster {
  display: none;
}

@media (max-width: 1200px) {
  .el-aside {
    display: none;
  }
  .el-header {
    margin-left: 0;
  }
  .el-main {
    margin-left: 0;
  }
  .pcPoster {
    display: none;
  }
  .mobilePoster {
    display: block;
  }
  .optionModule {
    display: block;
  }
}
</style>