<template>
  <el-container>
    <el-main>
      <transition name="el-fade-in">
        <el-row v-if="contentShow">
          <div class="backDropContainer">
            <div
              class="backDropImage"
              :style="`background-image: url('${fanart}')`"
            ></div>
          </div>
          <div class="backDropContainer backgroundContainer"></div>
          <el-col :span="24">
            <div
              class="mobileFanart"
              v-if="fanart != '/7d7d7d.png'"
              :style="`background-image: url('${fanart}')`"
            ></div>
            <div v-if="fanart === '/7d7d7d.png'" style="height: 60px"></div>
          </el-col>
          <el-col :span="24">
            <div class="movieInfo">
              <div class="posterContainer">
                <img
                  class="posterContent"
                  :src="movieInfo.pic.large.replace(/\.webp/, '.jpg')"
                />
              </div>
              <div class="movieDetail">
                <div class="movieTitle detailItem">
                  {{ movieInfo.title }}
                </div>
                <div
                  v-if="movieInfo.original_title != movieInfo.title"
                  class="movieOriginalTitle detailItem"
                >
                  <div>{{ movieInfo.original_title }}</div>
                </div>
                <div class="movieMInfo detailItem">
                  <div class="mInfoItem">
                    <i
                      style="color: red; font-size: 1.4em"
                      class="el-icon-star-on"
                    ></i>
                    <span>{{
                      movieInfo.rating ? movieInfo.rating.value : '暂无评分'
                    }}</span>
                  </div>
                  <div class="mInfoItem">
                    {{ movieInfo.year }}
                  </div>
                  <div class="mInfoItem">
                    发布于
                    {{
                      movieInfo.pubdate.length > 0
                        ? movieInfo.pubdate[0]
                        : "null"
                    }}
                  </div>
                  <div class="mInfoItem"></div>
                </div>
                <div class="movieTags detailItem">
                  <a
                    v-for="tag in movieInfo.f_tags.slice(0, 20)"
                    :key="tag.id"
                    @click="addTag(tag)"
                    class="tag"
                  >
                    {{ tag }}
                  </a>
                </div>
                <div class="movieDirectors detailItem">
                  <span v-if="movieInfo.directors.length > 0">导演: </span>
                  <a
                    v-for="director in movieInfo.directors"
                    :key="director.id"
                    :href="director.url"
                    class="director"
                  >
                    {{ director.name }}
                  </a>
                </div>
                <div class="movieBtns detailItem">
                  <button
                    @click="window.open(movieInfo.trailer.video_url)"
                    class="detailBtn"
                    v-if="movieInfo.trailer"
                  >
                    <i class="detailBtnIcon el-icon-film"></i>
                    <div class="detailBtnText">预告片</div>
                  </button>
                  <button
                    @click="window.open(movieInfo.sharing_url)"
                    class="detailBtn"
                  >
                    <i class="detailBtnIcon el-icon-link"></i>
                    <div class="detailBtnText">豆瓣</div>
                  </button>
                </div>
                <div class="movieIntro">
                  {{ movieInfo.intro }}
                </div>
              </div>
            </div>
          </el-col>
          <el-col :span="24">
            <div class="movieExtra">
              <div class="movieActors extraItem">
                <h4 style="margin-bottom: 6px; color: white">演职人员</h4>
                <div class="actorsContainer">
                  <div
                    v-for="director in movieInfo.directors.slice(0, 2)"
                    :key="director.id"
                    class="actor"
                  >
                    <div
                      v-if="director.avatar"
                      @click="window.open(director.url)"
                      class="actorImg"
                      :style="
                        'background-image: url(' +
                        director.avatar.normal.replace(/\.webp/, '.jpg') +
                        ')'
                      "
                    >
                      <div class="cover"></div>
                      <img
                        style="width: 1px; height: 1px"
                        :src="director.avatar.normal.replace(/\.webp/, '.jpg')"
                      />
                    </div>
                    <div
                      v-else
                      @click="window.open(director.url)"
                      class="actorImg"
                      :style="
                        'background-image: url(' +
                        'https://img3.doubanio.com/f/movie/8dd0c794499fe925ae2ae89ee30cd225750457b4/pics/movie/celebrity-default-medium.png' +
                        ')'
                      "
                    >
                      <div class="cover"></div>
                      <img
                        style="width: 1px; height: 1px"
                        src="https://img3.doubanio.com/f/movie/8dd0c794499fe925ae2ae89ee30cd225750457b4/pics/movie/celebrity-default-medium.png"
                      />
                    </div>
                    <span class="actorText"> {{ director.name }}</span>
                    <span class="actorText1">导演</span>
                  </div>
                  <div
                    v-for="actor in movieInfo.actors.slice(0, 5)"
                    :key="actor.id"
                    class="actor"
                  >
                    <div
                      v-if="actor.avatar"
                      @click="window.open(actor.url)"
                      class="actorImg"
                      :style="
                        'background-image: url(' +
                        actor.avatar.normal.replace(/\.webp/, '.jpg') +
                        ')'
                      "
                    >
                      <div class="cover"></div>
                      <img
                        style="width: 1px; height: 1px"
                        :src="actor.avatar.normal.replace(/\.webp/, '.jpg')"
                      />
                    </div>
                    <div
                      v-else
                      @click="window.open(actor.url)"
                      class="actorImg"
                      :style="
                        'background-image: url(' +
                        'https://img3.doubanio.com/f/movie/8dd0c794499fe925ae2ae89ee30cd225750457b4/pics/movie/celebrity-default-medium.png' +
                        ')'
                      "
                    >
                      <div class="cover"></div>
                      <img
                        style="width: 1px; height: 1px"
                        src="https://img3.doubanio.com/f/movie/8dd0c794499fe925ae2ae89ee30cd225750457b4/pics/movie/celebrity-default-medium.png"
                      />
                    </div>
                    <span class="actorText"> {{ actor.name }}</span>
                    <span class="actorText1"> 演员</span>
                  </div>
                </div>
              </div>
            </div>
          </el-col>
          <el-col :span="24">
            <div class="movieExtra">
              <div
                v-if="recommendMovies.length > 0"
                class="movieRelatedMovies extraItem"
              >
                <h4 style="margin-bottom: 6px; color: white">推荐影片</h4>
                <div class="relateMoviesContainer">
                  <div
                    v-for="movie in recommendMovies"
                    :key="movie.id"
                    class="relatedMovies"
                  >
                    <div
                      @click="gotoMovie(movie.id)"
                      class="relatedMoviesImg"
                      :style="
                        'background-image: url(' +
                        movie.pic.normal.replace(/\.webp/, '.jpg') +
                        ')'
                      "
                    >
                      <div class="cover"></div>
                      <img
                        style="width: 1px; height: 1px"
                        :src="movie.pic.normal.replace(/\.webp/, '.jpg')"
                      />
                    </div>
                    <span class="relatedMovieText"> {{ movie.title }}</span>
                  </div>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </transition>
    </el-main>
    <el-header>
      <transition name="el-fade-in">
        <el-col :span="12" :lg="11">
          <button class="backButton" @click="goBack()">
            <i class="el-icon-back"></i>
          </button>
        </el-col>
        <el-col :span="12"> </el-col>
      </transition>
    </el-header>
  </el-container>
</template>
<script type="application/javascript" ></script>
<script>
const axios = require("axios");
export default {
  name: "movie",
  data: function () {
    return {
      window: window,
      id: "",
      playOptions: [
        { label: "nPlayer" },
        { label: "VLC" },
        { label: "MXPlayer" },
        { label: "MXPlayer Pro" },
        { label: "PotPlayer" },
        { label: "IINA" },
      ],
      fanart: "",
      playType: "nPlayer",
      videoFiles: [],
      movieInfo: {},
      introFull: false,
      potplayer: `C:\\\\Program Files\\\\DAUM\\\\PotPlayer\\\\PotPlayerMini64.exe`,
      tipVisible: false,
      from: "movie",
      contentShow: false,
      playListVisible: false,
      recommendMovies: [],
    };
  },
  methods: {
    //初始化页面
    init: function () {
      // 从路由获取ID
      this.id = this.$route.params.id;
    },
    //获取电影数据
    getData: function () {
      let vm = this;
      axios
        .get(`https://douban.8610000.xyz/data/${vm.id}.json`)
        .then(function (response) {
          let data = response.data;
          vm.poster = data.pic.large;
          vm.fanart =
            data.extra &&
            data.extra.backdrops.length > 0 &&
            data.extra.backdrops[0] !== ""
              ? "https://www.themoviedb.org/t/p/original" +
                data.extra.backdrops[0]
              : "/7d7d7d.png";
          vm.movieInfo = data;
          vm.contentShow = true;
          // 获取推荐电影
          vm.getRecommendations();
        })
        .catch(function (error) {
          console.log(error);
          vm.$message({
            message: "暂未收录",
            type: "warning",
          });
        });
    },
    countLines: function (ele) {
      let styles = window.getComputedStyle(ele, null);
      let lh = parseInt(styles.lineHeight, 10);
      let h = parseInt(styles.height, 10);
      let lc = Math.round(h / lh);
      console.log("line count:", lc, "line-height:", lh, "height:", h);
      return lc;
    },
    //全排列
    // getGroup: function(data, index = 0, group = []) {
    //   var need_apply = new Array();
    //   need_apply.push(data[index]);
    //   for(var i = 0; i < group.length; i++) {
    //     need_apply.push(group[i]+','+data[index]);
    //   }
    //   group.push.apply(group, need_apply);

    //   if(index + 1 >= data.length) return group;
    //   else return this.getGroup(data, index + 1, group);
    // },
    //获取推荐电影
    getRecommendations: async function () {
      const response = await axios.get(
        `https://douban.8610000.xyz/recommendation/${this.id}.json`
      );
      this.recommendMovies = response.data.recommendations.slice(0, 7);
    },
    // 返回主页
    goBack: function () {
      if (this.from !== "other") {
        this.$router.go(-1);
      } else {
        this.$router.push({ path: "/home" });
      }
    },
    gotoMovie: function (mid) {
      this.$router.push({ path: `/movie/${mid}` });
    },
    // 标签筛选
    addTag: function (tag) {
      localStorage.newTag = tag;
      this.$router.push({ path: "/home" });
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      console.log(from.fullPath);
      if (from.fullPath === "/home") {
        vm.from = "home";
      } else if (from.fullPath.startsWith("/movie")) {
        vm.from = "movie";
      } else {
        vm.from = "other";
      }
    });
  },
  mounted: function () {
    this.init();
    this.getData();
  },
};
</script>

<style scoped>
* {
  -moz-user-select: none;
  -o-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.actorsContainer::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}

.backDropContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -2;
  touch-action: none;
}
.backDropImage {
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  contain: strict;
}
.backgroundContainer {
  background: linear-gradient(
    77deg,
    rgba(0, 0, 0, 0.96) 0,
    rgba(0, 0, 0, 0.7) 50%,
    rgba(0, 0, 0, 0.5) 100%
  ) !important;
  opacity: 1 !important;
  z-index: -1;
}

.el-dialog__title {
  color: #ecebeb !important;
}
.el-header {
  position: fixed;
  width: 100%;
  z-index: 2;
}
.backButton {
  margin-top: 12px;
  padding-top: calc(
    -20px + constant(safe-area-inset-top)
  ); /* 兼容 iOS < 11.2 */
  padding-top: calc(-20px + env(safe-area-inset-top)); /* 兼容 iOS >= 11.2 */
  margin-left: -20px;
  text-align: left;
  background: #fff0;
  font-size: 1.4rem;
  color: white;
  border: 0;
}
.backButton:hover {
  cursor: pointer;
}
.playOption {
  margin-top: 15px;
  display: inline-block;
  width: 100px;
  margin-left: 40px;
}

.el-main {
  margin-top: 50px;
  overflow: hidden;
}

.posterContainer {
  display: inline-block;
  width: 13vw;
  margin-bottom: 40px;
  margin-top: 20px;
}

.posterContent {
  width: 100%;
  border-radius: 6px;
}
.movieInfo {
  margin: 0 3.6vw 0 3.6vw;
  display: grid;
  grid-template-columns: 15vw auto;
}
.movieDetail {
  color: white;
}

.detailItem {
  margin-top: 0.46rem;
}
.movieMInfo {
  font-size: 0.73rem;
}
.movieTitle {
  font-size: 1.4rem;
  color: white;
  margin: 1vw 0 0 0;
  color: white;
}
.movieOriginalTitle {
  font-size: 0.75rem;
  margin-bottom: 0.6rem;
}
.mInfoItem {
  display: inline-block;
  color: white;
  margin-right: 0.6vw;
}

.movieDirectors,
.movieTags {
  font-size: 0.78rem;
}
.movieDirectors .director {
  margin: 0 0 0 0.3vw;
}
.movieDirectors a:hover {
  text-decoration: underline;
}
.movieDirectors a {
  text-decoration: none;
  color: white;
}

.movieTags .tag {
  margin: 0 0.3rem 0 0;
}
.movieTags a:hover {
  text-decoration: underline;
  cursor: pointer;
}
.movieTags a {
  text-decoration: none;
  color: white;
}
.movieIntro {
  margin-top: 20px;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  color: white;
  max-width: 54em;
  font-size: 0.8rem;
}
.playTitle {
  border-bottom: 1px solid #90939945;
  margin: 10px 0 0 10px;
  height: 30px;
  color: #9d3737;
  font-size: 18px;
}

.moviePlay {
  background: #262626f2;
  z-index: 100;
  position: fixed;
  left: 0;
  right: 100%;
  top: 0;
  bottom: 100%;
  width: 100%;
  height: 100%;
  color: white;
  padding-top: calc(
    -20px + constant(safe-area-inset-top)
  ); /* 兼容 iOS < 11.2 */
  padding-top: calc(-20px + env(safe-area-inset-top)); /* 兼容 iOS >= 11.2 */
}

.playLinks {
  overflow: scroll;
  height: calc(100% - 120px);
}
.playBox {
  display: -webkit-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.videoButton {
  min-width: 80px;
  min-height: 45px;
  background: rgba(85, 85, 85, 0.5);
  border: none;
}
.playLinks span {
  margin: 9px;
}
.playLinks a {
  margin: 9px 0;
}
.overview-intro-full {
  -webkit-line-clamp: initial !important;
}
.overview-intro {
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  color: white;
  max-width: 54em;
  font-size: 0.8rem;
}

.movieBtns {
  margin-left: -0.6rem;
  margin-top: 0.6rem;
}
.detailBtn {
  background: 0 0 !important;
  border: none;
}
.detailBtn:hover {
  cursor: pointer;
}
.detailBtnIcon {
  color: white;
  font-size: 2em;
  padding: 12px 25px;
  background: rgba(85, 85, 85, 0.3);
  -webkit-backdrop-filter: saturate(1.8) blur(1.5em);
  backdrop-filter: saturate(1.8) blur(1.5em);
  -webkit-border-radius: 0.42em;
  border-radius: 0.42em;
}
.detailBtnText {
  color: white;
  font-size: 1.2em;
}

.moreLessBtn {
  color: rgba(255, 255, 255, 0.6);
  font-size: 0.75rem;
}
.moreLessBtn:hover {
  text-decoration: underline;
  cursor: pointer;
}

.movieExtra {
  margin: 0 3.6vw 0 3.6vw;
}

.actorsContainer,
.relateMoviesContainer {
  white-space: nowrap;
  overflow-x: auto;
  min-height: 60px;
}
.actorsContainer::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
.relateMoviesContainer::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}

.actor,
.relatedMovies {
  display: inline-block;
  width: 9vw;
  margin: 0.6vw 1.2vw 0 0;
  text-align: center;
}

.actor .actorImg,
.relatedMovies .relatedMoviesImg {
  width: 100%;
  height: calc(9vw * 1.42);
  border-radius: 2px;
  background-position: center;
  background-size: cover;
}
.actor .cover {
  display: relative;
  width: 9vw;
  height: calc(9vw * 1.42);
  text-align: center;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
}
.actor .cover:hover {
  opacity: 1;
  transition: all 1s;
  cursor: pointer;
}

.relatedMovies .cover {
  display: relative;
  width: 9vw;
  height: calc(9vw * 1.42);
  text-align: center;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
}
.relatedMovies .cover:hover {
  opacity: 1;
  transition: all 1s;
  cursor: pointer;
}
.actorText,
.relatedMovieText {
  display: block;
  color: white;
  font-size: 0.7rem;
  margin-top: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.actorText1,
.relatedMovieText1 {
  display: block;
  color: rgba(255, 255, 255, 0.87);
  font-size: 0.68rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.mobileFanart {
  display: none;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  width: 100vw;
  height: calc(100vw * 0.5625);
  margin: 0 0 0 -20px;
  -webkit-mask-image: -webkit-linear-gradient(bottom, transparent 0, #000 50%);
}

/* 移动端 */
@media (max-width: 1000px) {
  .movieInfo {
    display: grid;
    grid-template-columns: 30vw auto;
  }
  .posterContainer {
    width: 25vw;
  }
  .actor,
  .relatedMovies {
    display: inline-block;
    width: 120px;
    margin: 0.6vw;
  }
  .actor .actorImg,
  .relatedMovies .relatedMoviesImg {
    height: calc(120px * 1.42);
  }
  .actor .actorImg .cover {
    display: none;
  }
  .relatedMovies .relatedMoviesImg .cover {
    display: none;
  }
}

@media (max-width: 600px) {
  .el-main {
    margin-top: -20px;
  }
  .mobileFanart {
    display: block;
  }
  .backDropContainer {
    display: none;
  }
  .movieInfo {
    grid-template-columns: auto;
    margin: 0 10px 0 10px;
  }
  .posterContainer {
    display: none;
  }
}
</style>
