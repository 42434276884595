import VueRouter from 'vue-router'
import Vue from 'vue'
import Home from '../components/Home'
import Movie from '../components/Movie'

//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)
 
const routes = [
  {
    path: '',
    redirect: '/home'
  },
  {
    path: '/home',
    component: Home,
    meta:{
      keepAlive:true,
    }
  },
  {
    path: '/movie/:id',
    component: Movie,
    meta:{
      keepAlive:false,
    }
  }
]
const router = new VueRouter({
  routes,
  mode: 'hash',
  linkActiveClass: 'active'
})
 
export default router