<template>
  <div>
    <transition>
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive"></router-view>
      </keep-alive>
    </transition>
    <transition>
      <router-view :key="$route.fullPath" v-if="!$route.meta.keepAlive"> </router-view>
    </transition>
  </div>
</template>
<script>
export default {
  name: "App",
};
</script>

<style>
#app {
  font-family: -apple-system, "Droid Sans", Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

/* 加载动画 */
.el-loading-mask {
  background-color:transparent;
}
.el-loading-spinner .path{
  stroke-width:5;
  stroke: white!important;
}

/* 暗黑模式 */
.el-dialog__title, .el-message-box__title{
    color: #ecebeb!important;
}
.el-dialog, .el-message-box{
  border:none;
  background: #262626;
}

.el-dialog__body, .el-message-box__message {
    color: #abafb7;
}
.el-input__inner, .el-textarea__inner{
  color: #ddd!important;
  background-color: #262626!important;
  border: 1px solid #757575!important;
}
.el-select-dropdown{
  border:none;
  background: #3a3a3a;
}
.el-popover{
  background:#464646;
  color:#f1f1f1;
  border:none;
}
/* 通知位置调整 */
.el-message-box {
    margin-top: 60px!important;
  }

</style>
